<template>
  <el-dialog
    append-to-body
    width="800px"
    top="2vh"
    :title="`${isChange ? '编辑客户' : '创建客户'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='100px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="客户名称" prop="name">
              <el-input
                size="mini"
                v-model.trim="form.data.name"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人" prop="contact">
              <el-input
                size="mini"
                v-model.trim="form.data.contact"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="contact_phone">
              <el-input
                size="mini"
                v-model.trim="form.data.contact_phone"
                placeholder="请输入(同步生成超管账号)"
                maxlength="11"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="营业地区" prop="area">
              <el-cascader
                v-model="form.data.area"
                :options="areaArr"
                :props="{ checkStrictly: false }"
                clearable
                filterable
                style="width:100%;">
              </el-cascader>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="详细地址" :rules="{
            required: true,
            message: '请选择',
          }"
          prop="address">
          <el-autocomplete
            v-model="form.data.address"
            :fetch-suggestions="querySearchAsync"
            placeholder="请输入关键词"
            @select="change"
            style="width:100%;"
          ></el-autocomplete>
          <div class="mapEdit" id="mapDivEdit" v-if="!isChange"></div>
        </el-form-item>
        <el-form-item label="下单渠道" prop="channel">
          <el-select v-model="form.data.channel" style="width:280px;">
            <el-option label="壹号净菜" :value="1"></el-option>
            <el-option label="兵哥快送" :value="2"></el-option>
            <el-option label="壹号进菜" :value="3"></el-option>
            <el-option label="赣农" :value="5"></el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="折扣率" prop="discount_type">
          <el-radio-group v-model="form.data.discount_type" v-removeAriaHidden>
            <el-radio :label="1">按商品分类</el-radio>
            <el-radio :label="2">按商品名称</el-radio>
          </el-radio-group>
          <div>
            <el-alert
              v-if="form.data.discount_type == 1"
              title="创建后请在操作中设置折扣体系"
              type="warning"
              :closable="false"
              size="mini">
            </el-alert>
            <el-alert
              v-if="form.data.discount_type == 2"
              title="创建后在添加商品报价时需要手动输入参价对象和折扣率，也可以使用导入询价表功能批量设置"
              type="warning"
              :closable="false"
              size="mini">
            </el-alert>
          </div>
        </el-form-item> -->
        <el-form-item label="结算方式" prop="order_type">
          <el-radio-group v-model="form.data.order_type" v-removeAriaHidden>
            <!-- 这两个ID 在编辑的时候  线上支付按钮 置灰 -->
            <el-radio :label="2" :disabled="form.data.id == 94 || form.data.id == 96" >线上支付</el-radio>
            <el-radio :label="1">线下结算</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="下单单价" prop="order_price">
          <el-radio-group v-model="form.data.order_price" v-removeAriaHidden>
            <el-radio :label="1">折后价</el-radio>
            <el-radio :label="2" >参考价</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="门头照" prop="image" class="idPhoto">
          <el-upload
            action=""
            :headers="{ token: token }"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :http-request="httpRequestFront"
            accept="image/jpeg, image/png, image/gif"
            >
            <img v-if="this.form.data.image" :src="this.form.data.image" class="avatar" style="object-fit: contain;">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item> -->
        <el-row>
          <el-col :span="12">
            <el-form-item label="售价小数" prop="point" >
              <el-select v-model="form.data.point">
                <el-option label="无小数" :value="0"></el-option>
                <el-option label="1位小数" :value="1"></el-option>
                <el-option label="2位小数" :value="2"></el-option>
                <el-option label="3位小数" :value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="超出规则" prop="point_rule">
              <el-radio-group v-model="form.data.point_rule" v-removeAriaHidden>
                <el-radio :label="1">四舍五入</el-radio>
                <el-radio :label="2">直接抹去</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="配送方式" prop="distr_text" >
              <el-input
                size="mini"
                v-model.trim="form.data.distr_text"
                placeholder="请输入"
                clearable
                style="width:100%;"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="起送金额" prop="surpass_amount" >
              <el-input
                size="mini"
                v-model.trim="form.data.surpass_amount"
                placeholder="请输入"
                clearable
                style="width:100%;"
                @input="form.data.surpass_amount=form.data.surpass_amount.match(/\d+(\.\d{0,2})?/) ? form.data.surpass_amount.match(/\d+(\.\d{0,2})?/)[0] : ''"
              ><span slot="suffix">元</span></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
     
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { getStorage } from '@/storage'
  import { mixinTable } from '@/mixins/table.js'
  export default {
    name: 'UserMerchantEdit',
    mixins: [mixinTable],
    data() {
      return {
        token: getStorage('token'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            name: '',
            contact: '',
            contact_phone: '',
            area: [],
            address: '',
            image: '',
            longitude: '',
            latitude: '',
            // discount_type: '', //折扣率类型 1:按商品分类 2:按商品名称
            order_type: '', //下单方式 1:线下结算 2:线上支付
            order_price: '', //下单显示价格 1:折后价 2:参考价
            distr_text: '今日下单次日配送，特殊情况请联系平台',// 配送方式
            point: '',
            point_rule: '',
            channel: '',
            surpass_amount: '',
          },
          rules: {
            name: [{ required: true, message:'必填项', trigger: 'change'}],
            contact: [{ required: true, message:'必填项', trigger: 'change'}],
            contact_phone: [{ required: true, message:'必填项', trigger: 'change'}],
            area: [{ required: true, message:'必填项', trigger: 'change'}],
            address: [{ required: true, message:'必填项', trigger: 'change'}],
            // discount_type: [{ required: true, message:'必填项', trigger: 'change'}],
            order_type: [{ required: true, message:'必填项', trigger: 'change'}],
            order_price: [{ required: true, message:'必填项', trigger: 'change'}],
            distr_text: [{ required: true, message:'必填项', trigger: 'change'}],
            point: [{ required: true, message:'必填项', trigger: 'change'}],
            point_rule: [{ required: true, message:'必填项', trigger: 'change'}],
            channel: [{ required: true, message:'必填项', trigger: 'change'}],
            surpass_amount: [{ required: true, message:'必填项', trigger: 'change'}],
          }
        },
        isChecked: true,
        areaArr: [],

        location: {lng: 0, lat: 0},
        mapEdit: null,
        localsearch: null,
        polygonPath: [],
        options1: [],

      }
    },
    methods: {
      getDetail(row) {
        this.getArea();
        if(!!row.id) {
          this.isChange = true
          // this.form.data.discount_type = row.discount_type;
          this.form.data.order_type = row.order_type;
          this.form.data.order_price = row.order_price;
          this.form.data.id = row.id;
          this.form.data.name = row.name;
          this.form.data.contact = row.main_account; // 联系人
          this.form.data.contact_phone = row.phone; // 联系人电话
          this.form.data.address = row.address;
          this.form.data.channel = row.channel;
          this.form.data.surpass_amount = row.surpass_amount;
          // this.form.data.image = row.image;
          this.form.data.area = [row.province_id,row.city_id,row.county_id]
          this.form.data.longitude = row.lng; // 经度
          this.form.data.latitude = row.lat; // 维度
          this.form.data.distr_text = !!row.distr_text ? row.distr_text : '今日下单次日配送，特殊情况请联系平台'; // 配送方式
          this.form.data.point = row.point; // 售价小数
          this.form.data.point_rule = row.point_rule; // 超出规则
        } else {
          this.tobmap();
        }
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        this.form.data = {
          id: '',
          name: '',
          contact: '',
          contact_phone: '',
          area: [],
          address: '',
          // image: '',
          // discount_type: '',
          order_type: '',
          order_price: '',
          distr_text:'今日下单次日配送，特殊情况请联系平台',
          point: '',
          point_rule: '',
          channel: '',
          surpass_amount: '',
        };
        done && done()
      },
      toggle(show) {
        this.visible = show;
        !show && this.reset()
      },
      // 获取全国省市区
      getArea() {
        this.$http.post('/common/district/all', {is_all:1}).then(res => {
          if(res.code === 1) {
            this.areaArr = res.data.list;
          }
        })
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let _params = {...this.form.data};
            _params.province_id = this.form.data.area[0];
            _params.city_id = this.form.data.area[1];
            _params.county_id = this.form.data.area[2]
            _params.lng = this.form.data.longitude; // 经度
            _params.lat = this.form.data.latitude; // 维度
            console.log(_params)

            let url = !!this.isChange ? '/admin/merchant/edit' : '/admin/merchant/add'
            this.form.loading = true;
            this.$http.post(url, _params).then(res => {
              if(res.code === 1) {
                if(!!this.isChange) {
                  this.$message.success("操作成功！")
                  this.toggle(false);
                  this.$emit('refresh')
                } else{
                  this.$message.success("操作成功！")
                  this.toggle(false);
                  this.$emit('refresh')
                  this.$alert('创建成功，请您及时设置折扣体系，并在分拣员和配送员列表绑定该客户', '', {
                    confirmButtonText: '知道了',
                    center: true,
                    callback: action => {}
                  });
                }
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      // 图片上传之前
      beforeAvatarUpload(file) {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          this.$message.error('上传文件大小不能超过 5MB!');
        }
        return isLt5M;
      },
      httpRequestFront(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.$http.post('/common/qiniu/upload', {formData, type:'upload'}).then(res => {
          if(res.code === 1) {
            this.form.data.image = res.data.all_path_url;
          } else {
            this.$message.error(res.msg)
          }
        })
      },

      tobmap() {
        console.log(this.mapEdit)
        !this.mapEdit &&
          !this.localsearch &&
          setTimeout(() => {
            this.mapEdit = new T.Map("mapDivEdit");
            //设置显示地图的中心点和级别
            this.mapEdit.centerAndZoom(
              new T.LngLat(
                this.form.data.longitude || 115.86086,
                this.form.data.latitude || 28.691483
              ),
              12
            );
            
            //创建面对象
            this.mapEdit.disableDoubleClickZoom();
            this.mapEdit.enableDrag();
            var config = {
              pageCapacity: 10, //每页显示的数量
              onSearchComplete: (result) => {
                //根据返回类型解析搜索结果
                console.log(result.getResultType());
                switch (parseInt(result.getResultType())) {
                  case 1:
                    //解析点数据结果
                    if (result.getPois()) {
                      this.options1 = result.getPois().map((item) => {
                        return {
                          value: item.name,
                          address: item.address,
                          lonlat: item.lonlat,
                        };
                      });
                    } else {
                      this.options1 = [];
                    }
                    break;
                }
              },
            };
            this.mapEdit.addEventListener("click", (res) => {
              console.log(res)
              console.log("-------------------")
              this.form.data.latitude = res.lnglat.lat;
              this.form.data.longitude = res.lnglat.lng;
              this.mapEdit.clearOverLays(); // 清除地图上所有覆盖物
              let marker = new T.Marker(
                new T.LngLat(res.lnglat.lng, res.lnglat.lat)
              );
              //向地图上添加标注
              this.mapEdit.addOverLay(marker);
              
            });
            //创建搜索对象
            this.localsearch = new T.LocalSearch(this.mapEdit, config);
            //创建标注对象
            let marker = new T.Marker(
              new T.LngLat(
                this.form.data.longitude || 115.86086,
                this.form.data.latitude || 28.691483
              )
            );
            //向地图上添加标注
            this.mapEdit.addOverLay(marker);
            this.confirmAddress(this.form.data.address);
          }, 500);
        if (this.mapEdit && this.localsearch) {
          setTimeout(() => {
            this.mapEdit.centerAndZoom(
              new T.LngLat(
                this.form.data.longitude || 115.86086,
                this.form.data.latitude || 28.691483
              ),
              12
            );
            this.mapEdit.clearOverLays();
            let marker = new T.Marker(
              new T.LngLat(
                this.form.data.longitude || 115.86086,
                this.form.data.latitude || 28.691483
              )
            );
            //向地图上添加标注
            this.mapEdit.addOverLay(marker);
          }, 500);
        }
      },
      change(e) {
        console.log(e)
        console.log("**************")
        if (e) {
          let Lng = e.lonlat.split(",")[0] * 1;
          let Lat = e.lonlat.split(",")[1] * 1;
          this.mapEdit.clearOverLays();
          this.mapEdit.panTo(new T.LngLat(Lng, Lat));
          //创建标注对象
          let marker = new T.Marker(new T.LngLat(Lng, Lat));
          //向地图上添加标注
          this.mapEdit.addOverLay(marker);
          this.form.data.latitude = Lat;
          this.form.data.longitude = Lng;
          this.form.data.address = e.address+e.value;
        }
      },
      // 搜索框的搜索事件
      confirmAddress(query) {
        this.localsearch && query && this.localsearch.search(query, 7);
      },
      querySearchAsync(queryString, cb) {
        console.log(queryString, "queryString", this);
        this.confirmAddress(queryString);
        setTimeout(() => {
          cb(this.options1);
        }, 500);
      },
      updatePolygonPath(e) {
        this.polygonPath = e.target.getPath();
      },

    },
    beforeDestroy() {
      if (this.mapEdit) {
        this.mapEdit.destroy(); // 销毁天地图对象
        this.mapEdit = null; // 重置为null
      }
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
  // /* .idPhoto .avatar{
  //   width: 100% !important;
  // } */
  .idPhoto .avatar-uploader-icon {
    width: 100px !important;
    height: 100px !important;
    line-height: 100px !important;
    border: 1px dashed #d9d9d9;
  }
  .mapEdit {
    height: 360px;
    width: 100%;
  }
</style>
